import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {cloneDeep} from 'lodash'
import filter from 'lodash/filter'
import {API} from "aws-amplify"
import { parse } from 'csv-parse/lib/sync'
import { 
  Box,
  Layer,
  Form,
  FormField,
  Text,
  Select,
  TextInput,
  Button,
  Spinner,
  Notification
} from 'grommet'
import { Add, Trash } from 'grommet-icons';

import configs from './configs'
export class AddQuote extends Component {
  constructor (props) {
    super(props)
    this.defaultHeaderValue = {
      currency: 'EUR',
      product: '',
      orderedIn: '',
      handbackDate: '',
      type: '',
      handoffName: '',
      jiraID: '',
      comment: ''
    };
    this.defaultQuoteValue = {
      language: '',
      generatedFromMetrics: '',
      managementFee: '',
      actualInEUR: '',
      vendor: '',
    }
    this.state = {
      value: {
        currency: 'EUR',
        product: '',
        orderedIn: '',
        handbackDate: '',
        type: '',
        handoffName: '',
        jiraID: '',
        comment: '',
        quotesToAdd: [{
          language: '',
          generatedFromMetrics: '',
          managementFee: '0',
          actualInEUR: '0',
          vendor: '',
        }],
      },
      submitting: false,
      failed: false
    }
  }

  onReset() {
    this.setState({
      value: {
        ...this.defaultHeaderValue,
        quotesToAdd: [{...this.defaultQuoteValue}]
      },
      submitting: false,
      failed: false
    })
  }


  onChangeField(nextValue, {touched}) {
    const {quotesToAdd} = nextValue;
    let keys = Object.keys(touched);
    const isChanged = keys.some(item => item.endsWith('vendor') || item.endsWith('generatedFromMetrics'));
    if(isChanged) {
      quotesToAdd.forEach((quote) => {
        let ratio = 1;
        if(quote.vendor === 'Milengo GmbH') {
          ratio = 0.1;
        } else if(quote.vendor === 'Centific') {
          ratio = 0.08;
        }
        let generatedFromMetrics = 0;
        const reg = /[0-9]+[.]*[0-9]*$/g;
        if(!quote.generatedFromMetrics.match(reg)) {
          generatedFromMetrics = 0;
        } else {
          generatedFromMetrics = quote.generatedFromMetrics;
        }
        generatedFromMetrics = parseFloat(generatedFromMetrics);
        quote.managementFee = generatedFromMetrics * ratio;
        quote.actualInEUR = generatedFromMetrics + quote.managementFee;
        quote.managementFee = quote.managementFee.toFixed(2);
        quote.actualInEUR = quote.actualInEUR.toFixed(2);
      });
    }
    this.setState({
      value: nextValue,
      submitting: false,
      failed: false
    })
  }

  onAddNewQuote(event) {
    const newQuote = cloneDeep(this.defaultQuoteValue);
    this.setState((oldState) => {
      return {
        value: {
          ...oldState.value,
          quotesToAdd: [...oldState.value.quotesToAdd, newQuote],
        }
      }
    })
  }

  onClickCloseMsg() {
    this.setState({
      failed: false
    })
  }

  onClickSubmit() {
    const {value} = this.state;
    this.setState({
      submitting: true
    })
    const quotesToAdd = value.quotesToAdd.map(item => ({
      id: uuidv4(),
      currency: 'EUR',
      product: value.product,
      orderedIn: value.orderedIn,
      handbackDate: value.handbackDate,
      type: value.type,
      handoffName: value.handoffName,
      jiraID: value.jiraID,
      comment: value.comment,
      language: item.language,
      generatedFromMetrics: item.generatedFromMetrics,
      managementFee: item.managementFee,
      actualInEUR: item.actualInEUR,
      vendor: item.vendor,
      mtWordCount: '',
      mtCost: '',
      htCost: ''
    }));
    const data = {
      body: {
        quotesToAdd,
      }
    }
    this.setState({
      submitting: true,
      failed: false,
    })

    API.post('Quotes', 'quotes', data)
      .then((res) => {
        this.setState({
          value: {
            ...this.defaultHeaderValue,
            quotesToAdd: [{...this.defaultQuoteValue}]
          },
          submitting: false,
          failed: false
        })
        const quoteList = parse(res.quotes, {
          columns: true,
          skip_empty_lines: true
        });
        quoteList.reverse();
        this.props.onExit({
          showAddPage: false,
          quoteList,
        });
      })
      .catch(err => {
        console.log(err)
        this.setState({
          submitting: false,
          failed: true
        })
      })
  }

  onRemoveQuote(index) {
    this.setState((state) => {
      const {value} = state;
      return {
        value : {
          ...value,
          quotesToAdd: filter(value.quotesToAdd, (item, _index) => index !== _index)
        }
      }
    });
  }


  render () {
    const {
      submitting,
      failed,
      value
    } = this.state

    let newQuotes = null;
    newQuotes = value.quotesToAdd && value.quotesToAdd.map((oneQuote, index) => (
      <Box key={index} direction="row" gap="xxsmall" justify="between" align="center">
        <FormField name={`quotesToAdd[${index}].vendor`} htmlFor="vendor" label="vendor" required>
          <Select id={`quotesToAdd[${index}].vendor`} name={`quotesToAdd[${index}].vendor`} options={configs.vendors} />
        </FormField>
        <FormField name={`quotesToAdd[${index}].language`} htmlFor="language" label="language" required>
          <Select
            id={`quotesToAdd[${index}].language`}
            name={`quotesToAdd[${index}].language`}
            options={configs.locales}
          />
        </FormField>
        <FormField name={`quotesToAdd[${index}].generatedFromMetrics`} htmlFor="generatedFromMetrics" label="generatedFromMetrics" required>
          <TextInput id={`quotesToAdd[${index}].generatedFromMetrics`}  name={`quotesToAdd[${index}].generatedFromMetrics`} />
        </FormField>
        <FormField name={`quotesToAdd[${index}].managementFee`} htmlFor="managementFee" label="managementFee" >
          <TextInput id={`quotesToAdd[${index}].managementFee`}  name={`quotesToAdd[${index}].managementFee`} disabled />
        </FormField>
        <FormField name={`quotesToAdd[${index}].actualInEUR`}  htmlFor="actualInEUR" label="actualInEUR" >
          <TextInput id={`quotesToAdd[${index}].actualInEUR`} name={`quotesToAdd[${index}].actualInEUR`} disabled />
        </FormField>
        <Box>
          <Button
            icon={<Trash />}
            label="Remove"
            plain
            hoverIndicator
            onClick={() => this.onRemoveQuote(index)}
          />
        </Box>
      </Box>
    ));

    return (<Layer
      full
      modal={true}
      margin='none'
      position="center"
      onEsc={() => this.props.onExit({showAddPage: false})}
      onClickOutside={() => this.props.onExit({showAddPage: false})}
    >
      <Box alignSelf='stretch' overflow='scroll'>
        <Button
          alignSelf='end'
          label="close"
          pad='large'
          margin='large'
          onClick={() => this.props.onExit({showAddPage: false})}
        />
        <Text  alignSelf='center' size='large' weight='bold'>Quote Details</Text>
        <Box alignSelf='center' pad='small' gap='xxsmall' justify="between" align="center" >
          <Form
            value={value}
            onChange={this.onChangeField.bind(this)}
            onSubmit={this.onClickSubmit.bind(this)}
            onReset={this.onReset.bind(this)}
          >
            <Box direction="row" gap="xxsmall">
              <FormField name="product" htmlFor="product" label="product" required>
                <TextInput id="product" name="product" />
              </FormField>
              <FormField name="orderedIn" htmlFor="orderedIn" label="orderedIn" required>
                <Select
                  id="orderedIn"
                  name="orderedIn"
                  options={configs.months}
                />
              </FormField>
              <FormField name="handbackDate" htmlFor="handbackDate" label="handbackDate" required>
                <Select
                  id="handbackDate"
                  name="handbackDate"
                  options={configs.months}
                />
              </FormField>
              <FormField name="type" htmlFor="type" label="type" required>
                <TextInput id="type" name="type" />
              </FormField>
              <FormField name="handoffName" htmlFor="handoffName" label="handoffName" required>
                <TextInput id="handoffName" name="handoffName" />
              </FormField>
              <FormField name="jiraID" htmlFor="jiraID" label="jiraID">
                <TextInput id="jiraID" name="jiraID" />
              </FormField>
              <FormField name="comment" htmlFor="comment" label="comment">
                <TextInput id="comment" name="comment" />
              </FormField>
            </Box>

            {newQuotes}

            <Button
              icon={<Add />}
              label="Add Quote"
              plain
              hoverIndicator
              onClick={this.onAddNewQuote.bind(this)}
            />
            <Box direction="row" justify="between" margin={{ top: 'medium' }}>
              {!submitting && value.quotesToAdd.length > 0 && <Button type="submit" primary label="Submit" />}
              {submitting && (<Box direction='row' gap="small">
                <Spinner size='small' />
                <Text>Submit In Progress...</Text>
                </Box>
              )}
              <Button type="reset" label="Reset Form" />
              <Button label="Cancel" onClick={() => this.props.onExit({showAddPage: false})} />
            </Box>

          </Form>
        </Box>
        {
          failed && <Box align='center' pad="medium">
            <Notification
              background='red'
              title="Error"
              message="Something went wrong!"
              onClose={this.onClickCloseMsg.bind(this)}
            />
          </Box>
        }
      </Box>
    </Layer>
    )
  }
}

export default AddQuote
