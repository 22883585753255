const configs = {
	defaultValue: {
		currency: 'EUR',
		id: '',
		product: '',
		orderedIn: '',
		handbackDate: '',
		language: '',
		vendor: '',
		type: '',
		handoffName: '',
		generatedFromMetrics: '',
		managementFee: '',
		actualInEUR: '',
		jiraID: '',
		comment: ''
	},
	months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	locales: ['de_DE', 'es_ES', 'fr_FR', 'it_IT', 'ja_JP', 'ko_KR', 'nl_NL', 'pl_PL', 'pt_BR', 'ru_RU', 'sv_SE', 'tr_TR', 'zh_CN', 'zh_TW'],
	vendors: ['Centific', 'Milengo GmbH'],
};

export default configs