import React, { Component } from 'react';
import { Box, Button, DataTable, Text } from 'grommet';
import { qlikBrand } from '../../branding';
import Spinner from 'react-svg-spinner';
import { API } from "aws-amplify";
import { parse } from 'csv-parse/lib/sync';
import AddQuote from '../../components/Quote/addQuote';
import DownloadQuote from '../../components/Quote/downloadQuote';
import UpdateQuote from '../../components/Quote/updateQuote';
import { cloneDeep } from 'lodash';

export class QuoteOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteList: [],
      fetchQuotesInProgress: true,
      showAddPage: false,
      showDownloadPage: false,
      showUpdatePage: false,
      rowToEdit: {}
    };
  }

  async componentDidMount() {
    API.get('Quotes', 'quotes')
      .then((res) => {
        const quoteList = parse(res.quotes, {
          columns: true,
          skip_empty_lines: true
        });
        quoteList.reverse();
        this.setState({ quoteList, fetchQuotesInProgress: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ fetchQuotesInProgress: false });
      });
  }

  setShowAddPage({ showAddPage, quoteList }) {
    const value = {
      showAddPage,
    };
    if (quoteList) value.quoteList = quoteList;
    this.setState(value);
  }

  setShowDownloadPage({ showDownloadPage, quoteList }) {
    const value = {
      showDownloadPage,
    };
    if (quoteList) value.quoteList = quoteList;
    this.setState(value);
  }

  setShowUpdatePage({ showUpdatePage, quoteList }) {
    const value = {
      showUpdatePage,
    };
    if (quoteList) value.quoteList = quoteList;
    this.setState(value);
  }

  onClickRow({ datum }) {
    // const data = cloneDeep(datum)
    this.setState({
      showUpdatePage: true,
      rowToEdit: cloneDeep(datum)
    });
  }



  render() {
    const { quoteList, fetchQuotesInProgress, showAddPage, showDownloadPage, showUpdatePage, rowToEdit } = this.state;
    const headers = ['product', 'orderedIn', 'language', 'vendor', 'handoffName', 'generatedFromMetrics', 'managementFee', 'actualInEUR', 'jiraID', 'comment', 'mtWordCount', 'mtCost', 'htCost'];
    const columns = headers.map(item => {
      const res = {
        property: item,
        header: item
      };

      const searchable = ['orderedIn', 'handoffName'];
      res.search = searchable.includes(item) ? true : false;

      if (item === 'generatedFromMetrics') res.header = 'FromMetrics';
      if (item === 'managementFee') res.header = 'MF';
      if (item === 'actualInEUR') res.header = 'actual';
      if (item === 'mtWordCount') res.header = 'mtWC';
      if (item === 'jiraID') res.header = 'jira';
      return res;
    });
    return (
      <Box align='stretch' fill='horizontal' jusify='center'>
        <Box align='center'>
          <Text size='large' weight='bold'>Quotes Preview</Text>
        </Box>
        {
          fetchQuotesInProgress && <Box align='center'><Spinner size='80px' /></Box>
        }
        {
          !fetchQuotesInProgress && (<Box align='center' alignSelf='center'
            size='small' pad='large' gap='xsmall'>
            <DataTable align='center'
              columns={columns}
              data={quoteList}
              paginate={true}
              step={20}
              primaryKey='id'
              onClickRow={this.onClickRow.bind(this)}
            />
          </Box>)
        }
        <Box alignSelf='center' pad='large' gap='xsmall' direction="row">
          <Button
            primary
            color={qlikBrand.flood.turquoise}
            gap='large'
            label="Add Quote"
            size="large"
            hoverIndicator
            onClick={() => this.setShowAddPage({ showAddPage: true })}
          />
          {showAddPage && <AddQuote
            onExit={this.setShowAddPage.bind(this)}
          />}
          <Button
            primary
            color={qlikBrand.flood.turquoise}
            gap='large'
            label="Download Quotes"
            size="large"
            hoverIndicator
            onClick={() => this.setShowDownloadPage({ showDownloadPage: true })}
          />
          {showDownloadPage &&
            <DownloadQuote
              columns={columns}
              searchAble={['product', 'orderedIn', 'language', 'vendor', 'handoffName', 'jiraID']}
              quoteList={quoteList}
              onExit={this.setShowDownloadPage.bind(this)}
            />}
        </Box>
        <Box alignSelf='center' pad='large' gap='xsmall'>
          {showUpdatePage && <UpdateQuote
            defaultValue={rowToEdit}
            onExit={this.setShowUpdatePage.bind(this)}
          />}
        </Box>
      </Box>
    );
  }
}

QuoteOperations.propTypes = {
  // quoteList: PropTypes.array.isRequired
};

export default QuoteOperations;
